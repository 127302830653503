<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Leave Request</h2>
        <v-row>
            <v-col cols="12" >
                <v-card outlined elevation="2" style="padding: 10px;">
                    <v-col class="d-flex" cols="12" style="padding-bottom: 0px !important;">
                        <v-select :items="typesleave" item-text="name" :rules="rules" v-model="type" item-value="type" label="Type of leave" outlined></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="0"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFrom"
                                label="Date From"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateFrom"
                            @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-left="0"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateTo"
                                label="Date To"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateTo"
                            @input="menu3 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col class="d-flex" cols="12" style="padding-bottom: 0px !important;">
                        <v-select :items="users" item-text="name" :rules="rules" v-model="supervisor" item-value="id" label="Supervisor / Manager" outlined></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" style="padding-bottom: 0px !important;">
                        <v-textarea label="Comments" v-model="comment" hint="Enter Text ..."></v-textarea>
                    </v-col>
                </v-card>
            </v-col>
            <v-col class="d-flex justify-start" cols="12">
                <v-btn outlined  color="primary" class="mr-5" @click="leaveCancel">Cancel</v-btn>
                <v-btn depressed color="primary" @click="leaveRequest">Request</v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>

<style>
.v-text-field__details{
  display: none !important;
}
</style>

<script>
import { mapGetters,mapActions } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name: "LeaveRequest",
    data: () => ({
        typesleave: [
            { name: "Paid Leave", type: "paid"},
            { name: "No Pay Leave", type: "nopay"},
            { name: "Off Day Leave", type: "offday"},
            { name: "Sick leave", type: "sick"},
            { name: "Paternity leave", type: "paternity"},
            { name: "Maternity leave", type: "maternity"},
            { name: "Other type of leave", type: "other"}
        ],
        user_p : JSON.parse(localStorage.getItem('user')),
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        rules: [
            value => !!value || "Required."
        ],
        comment: "",
        type: "",
        supervisor: "",
        menu: false,
        modal: false,
        menu2: false,
        menu3: false,
    }),
    computed: {
        ... mapGetters ( {users : "getAllUsers"})
    },
    methods:{
        ... mapActions (['fetchAllUsers']),
        leaveCancel() {
            this.$router.push('/leave')
        },
        async leaveRequest () {
            let formData = new FormData();
            formData.append("comment",this.comment)
            formData.append("date_from", this.dateFrom)
            formData.append("date_to", this.dateTo)
            formData.append("type", this.type)
            formData.append("user", this.user_p.id)
            formData.append("usertype", this.user_p.type)
            formData.append("supervisor",this.supervisor)
            axios.post(
                "https://camusatweb.ubuniworks.com/api/v1/leave",
                formData
            ).then((response) => {
                if(response.data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'Leave request successfully submitted',
                        text: 'You will be informed once it has been reviewed'
                    })
                    this.$router.push('/leave')
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Leave request submission failed',
                        text: response.data.message
                    })
                }
            })
            
        }
    },
    mounted () {
        this.fetchAllUsers()
    }
}
</script>